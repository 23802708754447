import { ChakraProvider } from "@chakra-ui/react";
import Layout from "./Layout";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import popoverTheme from "./theme/PopoverTheme";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const router = createBrowserRouter([{ path: "*", element: <Layout /> }]);

export const App = () => (
  <QueryClientProvider client={queryClient}>
    <ChakraProvider theme={popoverTheme}>
      <RouterProvider router={router} />
    </ChakraProvider>
  </QueryClientProvider>
);
