import { useQuery } from "@tanstack/react-query";
import { getSpaceUsers } from "./api";
import { Loading } from "./components/Loading";
import {
  Box,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";

export default function Space() {
  const { spaceId } = useParams();
  const { isLoading, data } = useQuery({
    queryKey: ["getSpace", spaceId],
    queryFn: () => getSpaceUsers(spaceId ?? ""),
    refetchInterval: 1000,
  });
  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box p={4}>
      <Heading pb={4}>All Students</Heading>
      <TableContainer border={"1px"} borderRadius={"10px"}>
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Name</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data?.map((d) => {
              return (
                <Tr>
                  <Td>{d.beacon_id}</Td>
                  <Td>{d.name}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}
