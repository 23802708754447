import { useQuery } from "@tanstack/react-query";
import { getSpaces } from "./api";
import { Loading } from "./components/Loading";
import {
  Box,
  Button,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

export default function Home() {
  const { isLoading, data } = useQuery({
    queryKey: ["getSpaces"],
    queryFn: getSpaces,
  });
  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box p={4}>
      <Heading pb={4}>All Locations</Heading>
      <TableContainer border={"1px"} borderRadius={"10px"}>
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Name</Th>
              <Th>See Users</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data?.map((d) => {
              return (
                <Tr>
                  <Td>{d.gateway_id}</Td>
                  <Td>{d.name}</Td>
                  <Td>
                    <Button as="a" href={`/space/${d.id}`}>
                      See
                    </Button>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}
