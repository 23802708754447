import axios from "axios";

export const serveBasePath =
  process.env.REACT_APP_SERVER_BASE_PATH ?? "http://localhost:9000";
export const apiPath = `${serveBasePath}${
  process.env.REACT_APP_API_PATH ?? "/api/v1"
}`;

export interface Space {
  id: string;
  create_time: Date;
  update_time: Date;
  gateway_id: string;
  name: string;
}

export interface User {
  id: string;
  create_time: Date;
  update_time: Date;
  beacon_id: string;
  name: string;
}

export const getSpaces = async (): Promise<Space[]> => {
  const { data } = await axios.get<Space[]>(`${apiPath}/spaces`);
  return data;
};

export const getSpaceUsers = async (id: string): Promise<User[]> => {
  const { data } = await axios.get<User[]>(`${apiPath}/space/${id}/users`);
  return data;
};
